import React from 'react';
import LocaleLink from './LocaleLink';

const SearchHit = ({ hit: { objectID, name, price, image } }) => (
  <section className="flex flex-col flex-wrap products">
    <article key={objectID} className="product">
      <LocaleLink
        to={`/products/${name.replace(/\s+/g, '-')}`}
        className="flex w-full h-full no-underline"
      >
        <div className="relative w-full px-3 py-6 overflow-hidden cursor-pointer md:px-6">
          <img src={image[0]} alt={name} title={name} />

          <div className="pt-3 text-center md:pt-6">
            <p className="mb-1 font-serif text-lg font-bold text-primary">
              {name}
            </p>
            <p className="text-sm text-primary">£{price}</p>
          </div>
        </div>
      </LocaleLink>
    </article>
  </section>
);

export default SearchHit;
