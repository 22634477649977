import React from 'react';
import { Hits } from 'react-instantsearch-dom';
import SearchFilter from './SearchFilter';
import SearchHit from './SearchHit';

const SearchResults = () => (
  <div className="flex flex-wrap">
    <div className="z-10 w-full pr-px -ml-px border-black">
      <SearchFilter />
    </div>

    <main className="flex flex-wrap w-full">
      <Hits hitComponent={SearchHit} />
    </main>
  </div>
);

export default SearchResults;
