import React from 'react';
import { SearchBox } from 'react-instantsearch-dom';

// import { SearchBox, RefinementList } from 'react-instantsearch-dom';
// need this for the refinement modal

const SearchFilter = () => (
  <React.Fragment>
    <div
      // className="-ml-px"
      className="text-primary"
      // style={{
      //   marginRight: '2px',
      // }}
    >
      <SearchBox />
    </div>
    {/* <div className="mb-6">
      <h3 className="block mb-2 font-mono text-sm font-bold tracking-widest text-primary">
        Category
      </h3>
      <RefinementList attribute="categorories" />
    </div>

    <div className="mb-6">
      <h3 className="block mb-2 font-mono text-sm font-bold tracking-widest text-primary">
        Collection
      </h3>
      <RefinementList attribute="collections" />
    </div>

    <div className="mb-6">
      <h3 className="block mb-2 font-mono text-sm font-bold tracking-widest text-primary">
        Misc
      </h3>
    </div> */}
  </React.Fragment>
);

export default SearchFilter;
