import React from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-dom';

import Seo from '../components/SEO';
import SearchResults from '../components/SearchResults';

const client = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_API_KEY
);

function SearchPage() {
  return (
    <InstantSearch
      searchClient={client}
      indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
    >
      <Seo pageTitle="search" />
      <div
        className="mt-16 mb-6 md:mt-20 lg:mt-12"
      >
        <h1 className="p-4 mr-px -ml-px text-4xl font-bold text-white md:text-4xl bg-primary">
          search
        </h1>
        <SearchResults />
      </div>
    </InstantSearch>
  );
}

export default SearchPage;
